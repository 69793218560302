.container {
  min-height: 100vh;
  width: 100vw;
  transition: background .3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  &--light {
    background: $white;
    .cell {
      &--wall {
        background: $white;
      }
      &--empty {
        background: #4c4d56;
        border-left: 1px solid #797d8c;
        border-top: 1px solid #797d8c;
      }
      &--filled {
        border-left: 1px solid #797d8c;
        border-top: 1px solid #797d8c;
      }
    }
    .logo__text {
      color: $primary-text;
    }
    .nav__link {
      color: $text;
      :visited {
        color: $primary-text;
      }
      &--active {
        color: $primary-text;
      }
    }
    .menu {
      &__separator {
        background: #d3d3d3;
      }
      &__button {
        background: #edeff1;
        &:hover {
          background: #e3e5e7;
        }
      }
    }
    .controls {
      &__button {
        color: #5d656f;
        background: #eaedf3;
        &:hover {
          background: #e5e8ee;
        }
        &--primary {
          background: #d1d7e0;
          &:hover {
            background: #ccd1db;
          }
        }
      }
    }
  }
  &--dark {
    background: $dark;
    .cell {
      &--wall {
        background: $dark;
      }
      &--empty {
        background: #383e47;
        border-left: 1px solid $dark;
        border-top: 1px solid $dark;
      }
      &--filled {
        border-left: 1px solid $dark;
        border-top: 1px solid $dark;
      }
    }
    .logo__text {
      color: $white;
    }
    .nav__link {
      color: #a3a3a3;
      :visited {
        color: #d7dae0;
      }
      &--active {
        color: #d7dae0;
      }
    }

    .menu {
      &__separator {
        background: #333942;
      }
      &__button {
        background: #30343a;
        &:hover{
          background: #3a3e44;
        }
      }
    }
    .controls {
      &__button {
        color: #f5f5f5;
        background: #282d34;
        &:hover {
          background: #2d3239;
        }
        &--primary {
          background: $darker;
          &:hover {
            background: #383e47;
          }
        }
      }
    }
  }
}
