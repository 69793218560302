.controls {
  font-family: "Nunito Semi Bold", sans-serif;
  margin-bottom: 40px;
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  &__text {
    color: #a3a3a3;
    font-size: 20px;
    text-align: center;
    letter-spacing: 2px;
    width: 150px;
  }
  &__button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 18px;
    justify-content: space-between;
    overflow: hidden;
    transition: all .5s ease-in-out;
    border: none;
    outline: none;
    border-radius: 50%;
    margin: 0 20px;
    img {
      width: 20px;
      height: 20px;
      transition: transform .2s;
    }
    &--primary {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px;
      transition: all .3s ease-in-out;
      &:hover {
        transform: rotate(-15deg) scale(1.1);
      }
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}
